




















































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import ModalMixin from '../../mixins/ModalMixin'
import { GrammarLesson, GrammarStage, GrammarSubject } from '../../api/types/grammar/grammarLessons'
import { Api, ISO8601Date } from '../../api'
import { HomeworkInfo } from '@/edshed-common/api'

@Component({ components: { } })
export default class GrammarNodeSelector extends Mixins(ModalMixin) {
  @Prop({ default: () => { return [] } }) private data!: GrammarStage[]
  @Prop({ default: false }) private customStyle!: boolean
  @Prop({ default: false }) private disableClear!: boolean
  @Prop({ default: false }) private disabled!: boolean
  @Prop({ default: -1 }) private node_id!: number
  @Prop({ default: () => { return [] } }) private homeworks!: HomeworkInfo[]
  @Prop({ default: null }) private selectedHomework!: HomeworkInfo | null
  private loading = true

  private filterDrawerIsOpen = false
  private showHomework = false

  private selectedLesson: GrammarLesson | null = null

  private filterOptions = {
    stage: -1,
    subject: -1,
    topic: -1,
    lesson: -1
  }

  private rootLevel = {
    title: 'Stages',
    id: -1
  }

  private grammarStages: GrammarStage[] = []

  @Watch('selectedHomework')
  onSelectedHomeworkUpdated (value: HomeworkInfo | null, oldValue: HomeworkInfo | null) {
    if (value && oldValue && value.id === oldValue.id) { return }
    if (value) {
      this.selectedLesson = this.searchForLesson(value.list_id!)
    } else {
      this.resetFilters()
    }
  }

  mounted () {
    if (this.data.length) {
      this.grammarStages = this.data
    } else {
      this.getLessonData()
    }
  }

  get selectedStage () {
    return this.grammarStages.find(x => x.id === this.filterOptions.stage)
  }

  get selectedSubject () {
    if (this.selectedStage) {
      return this.selectedStage.subjects.find(x => x.id === this.filterOptions.subject)
    }
    return undefined
  }

  get selectedTopic () {
    if (this.selectedSubject) {
      return this.selectedSubject.topics.find(x => x.id === this.filterOptions.topic)
    }
    return undefined
  }

  get selectedNodeId () {
    if (this.selectedLesson) {
      return this.selectedLesson.id
    }
    return -1
  }

  get stages () {
    return this.grammarStages
  }

  get showFilterTitle () {
    return false
  }

  get selectedLevel () {
    if (this.filterOptions.stage < 0) {
      return this.rootLevel
    }
    if (this.filterOptions.subject < 0) {
      const stage = this.grammarStages.find(x => x.id === this.filterOptions.stage)
      if (stage) {
        return stage
      }
    }
    if (this.filterOptions.topic < 0) {
      const stage = this.grammarStages.find(x => x.id === this.filterOptions.stage)
      if (stage) {
        const subject = stage.subjects.find(x => x.id === this.filterOptions.subject)
        if (subject) {
          return subject
        }
      }
    }
    const stage = this.grammarStages.find(x => x.id === this.filterOptions.stage)
    if (stage) {
      const subject = stage.subjects.find(x => x.id === this.filterOptions.subject)
      if (subject) {
        const topic = subject.topics.find(x => x.id === this.filterOptions.topic)
        if (topic) {
          return topic
        }
      }
    }
    return this.rootLevel
  }

  private async getLessonData () {
    try {
      const data = await Api.getGrammarCurriculumNodes('en_GB')
      if (data) {
        this.grammarStages = data
      } else {
        this.grammarStages = []
      }
      if (this.node_id > -1) {
        this.selectedLesson = this.searchForLesson(this.node_id)
      }
      this.loading = false
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({ title: error.name, message: error.message, console: error.stack })
      }
    }
  }

  private openHomeworkDrawer () {
    this.resetFilters()
    this.filterDrawerIsOpen = true
    this.showHomework = true
  }

  private selectStage (id: number) {
    this.showHomework = false
    this.filterDrawerIsOpen = true
    this.filterOptions.stage = id
    this.filterOptions.subject = -1
    this.filterOptions.topic = -1
  }

  private selectSubject (id: number) {
    this.filterDrawerIsOpen = true

    this.filterOptions.subject = id
    this.filterOptions.topic = -1
  }

  private selectTopic (id: number) {
    this.filterDrawerIsOpen = true

    this.filterOptions.topic = id
  }

  private selectLesson (lesson: GrammarLesson) {
    this.selectedLesson = lesson
    this.filterDrawerIsOpen = false
    this.$emit('input', this.selectedNodeId)
  }

  private onHomeworkClick (homework: HomeworkInfo) {
    this.$emit('homework-selected', homework)
    this.showHomework = false
    this.filterDrawerIsOpen = false
  }

  private resetFilters () {
    this.filterDrawerIsOpen = false
    this.filterOptions.stage = -1
    this.filterOptions.subject = -1
    this.filterOptions.topic = -1
    this.selectedLesson = null
    this.$emit('input', this.selectedNodeId)
    this.$emit('homework-selected', null)
  }

  private searchForLesson (id: number) {
    let result = null

    function search (currentData: any[]) {
      for (const item of currentData) {
        if (item.id === id) {
          result = item
        }
        if (item.subjects || item.topics || item.lessons) {
          search(item.subjects || item.topics || item.lessons)
        }
        console.log(item.id)
      }
    }

    search(this.grammarStages)
    return result
  }

  private prettyDate (dt: ISO8601Date) {
    moment.locale(this.$store.state.user.locale)
    return moment(dt).format('L LT')
  }
}
